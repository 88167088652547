import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { ArrowLeftIcon, Pane, Button } from 'evergreen-ui';

import { Layout, Link, AffiliateDisclosure } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const ReferralCodePage = ({ data, location }) => {
    const page = data.ghostPage

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location}>
                <div className="container referral-info">
                    <article className="content">
                        <section className="post-full-content">
                            {/* <Link to="/referrals/">
                                <Button iconBefore={ArrowLeftIcon} appearance="minimal" size="large" intent="info" color="#26a8ed">
                                    All Sign Up Bonuses
                                </Button>
                            </Link> */}
                            <Link to="/referrals/"><ArrowLeftIcon marginBottom={4} /> All Referral Codes</Link>
                        </section>
                        { page.feature_image ?
                            <figure className="post-feature-image">
                                <img width="2" height="1" src={ page.feature_image } alt={`${page.title} Logo`} />
                            </figure> : null }
                        <section className="post-full-content">
                            <h1 className="content-title">{page.title}</h1>

                            {/* The main page content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: page.html }}
                            />
                            <Pane className="post-full-content" textAlign="center">
                                <hr/>
                                <Link to="/bitcoin-btc-interest/" style={{ textDecoration: 'none' }}>
                                    <Button marginRight={12} marginBottom={12} size="large" appearance="primary">
                                        Compare Best Rates by Cryptocurrency
                                    </Button>
                                </Link>
                                <Link to="/referrals/" style={{ textDecoration: 'none' }}>
                                    <Button marginRight={12} marginBottom={12} size="large">
                                        More Referral Codes / Sign Up Bonuses
                                    </Button>
                                </Link>
                            </Pane>
                            <AffiliateDisclosure large />
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default ReferralCodePage

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
